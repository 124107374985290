.pagination .bg-purple-600{
    background-color: #F54849 !important;
    box-shadow: 0 0 0 3px rgba(245, 72, 73, 0.2);
}
.input-error{
    border: 1px solid red !important;
}
.error-text{
    color: red;
    font-size: 0.2rem;
}
.hide-scrollBar::-webkit-scrollbar { 
    display: none; 
} /* Hide the scrollbar for other browsers */ 
.hide-scrollBar { 
    scrollbar-width: none; 
    /* height: fit-content; */
    /* max-height: 500px; */
    /* height: 70% !important; */
}
*::-webkit-scrollbar{
    width: 5px;
    height: 5px;
}
*::-webkit-scrollbar-thumb{
    background-color: #F54849;
    border-radius: 5px;
}
.pagination span{
    font-size: .7rem !important;
}
.pagination{
    display: flex;
    justify-content: space-between;
}